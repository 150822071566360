import BgServer from "../BgServer";
import { NuxtAxiosInstance } from "@nuxtjs/axios";

import TransportAxios from "../transport/TransportAxios";
import TradeConnector from "../connectors/TradeConnector";
import TradeConnectorYandex from "./TradeConnectorYandex";

import { Context } from '@nuxt/types'
import logger from "../utility/logger";
import UserConnector from "../connectors/UserConnector";
import UserConnectorYandex from "./UserConnectorYandex";

import { v4 as uuidv4 } from "uuid";
import { IUserInfo } from "../connectors/SocketConnector";
import { sharedStorageLocal } from "../SharedStorage";
// TODO write index.d.ts fr yandex sdk
// example https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/yandex-money-sdk
// 
//https://yandex.ru/dev/games/doc/dg/sdk/sdk-player.html
interface YandexPlayer {
    signature:string,  // Используйте player.signature для авторизации на своем сервере

    getUniqueID(): string

    setData( data:any, flush:boolean): Promise<any>
}

interface  YandexSDK {
    getPlayer(params:any): Promise<YandexPlayer>
    getPayments(params:any): Promise<any>
}


export default class BgServerYandex extends BgServer {
    context:Context    
    purchaseEnable: boolean
    yandexSdk?: YandexSDK | any
    player?: YandexPlayer
    payments ?: any
    connectorUserYandex?: UserConnectorYandex 

    constructor(axios: NuxtAxiosInstance, context: Context) {
        super(axios);
        this.purchaseEnable = false;
        this.context = context
    }

    async init() {
        try {
           await this.initSDK();
        } catch(e) {
            logger.warn("Error init sdk yandex", e);
        }

        await super.init();                                      
    }

    /** load existed anonimous id or create new one if not exists */
    doLoadAnonimId():string {
        const YA_ANONIM_ID_NAME = "ya_anonim_id"
        let anonimId = sharedStorageLocal.get( YA_ANONIM_ID_NAME )
        if(!anonimId) {
            anonimId = uuidv4();
            sharedStorageLocal.set( YA_ANONIM_ID_NAME, anonimId )
        }
        return anonimId;
    }

     
    async doLogin() {
        
        if(!this.connectorUserYandex)
            return; 

        if(process.env.YANDEX_DEV_PLAYER_UUID) 
            return this.doLoginPlayerDebug(process.env.YANDEX_DEV_PLAYER_UUID)
                            
        if(this.player) 
            return this.doLoginPlayer(this.player);

        return this.doLoginAnonim(this.doLoadAnonimId());                         
    }

    /** An example on how to implement proper Yandex login in the future.*/
    private async doLoginPlayerDebug(uuid:string) {
        if(!this.connectorUserYandex)
            return;   
        let anonimId = this.doLoadAnonimId()                          
        let signature = "id:"+uuid;
        return this.connectorUserYandex.doLoginYandex(signature, anonimId)
    }
    
    private async doLoginAnonim(anonimId:string) {
        if(!this.connectorUserYandex)
            return;   
        console.log( 'Yandex: performing doLoginYandexAnonim ...' );
        return this.connectorUserYandex.doLoginYandexAnonim(anonimId)
    }

    private async doLoginPlayer(player:YandexPlayer) {
        console.log( 'Yandex: performing doLoginPlayer ...' );                            
        if(!this.connectorUserYandex)
            return;
        
        let anonimId = this.doLoadAnonimId()                  
        let signature = player.signature 
        if(!signature)
            signature = "id:"+player.getUniqueID();
        return this.connectorUserYandex.doLoginYandex(signature, anonimId)
    }
    
    
    createUserConnector(transport:TransportAxios) {        
        // https://yandex.ru/dev/games/doc/dg/sdk/sdk-player.html
        this.connectorUserYandex =  new UserConnectorYandex(this.socket, transport, this);       
        this.connectorUserYandex.verbose = true;

        if(process.env.YANDEX_EMULATE_FAILER)
            this.connectorUserYandex.debugEmulateFailer = true

        this.connectorUserYandex.on(UserConnector.EVENT_USER_INFO, (data: IUserInfo)=>{            
            // TODO login even we already logined                        
            this.doAuthroizationAndRetry( async ()=> this.doLogin() )             
        })
        return this.connectorUserYandex
    }
    
    initConnectorTrade() {                        
        const connector : TradeConnector = new TradeConnectorYandex( this.socket, this )
        connector.verbose = process.env.DEBUG_VERBOSE == "true"
        return connector       
    }

    async initSDK() {
        //try-catch supposed to make rid of "YaGames is undefined" errors:
        try {
            //@ts-ignore
            this.yandexSdk = await YaGames.init()
        }
        catch(e) {
            console.error( 'Failed to YaGames.init():', e )
        }
        //@ts-ignore
        logger.log('Yandex SDK initialized. environment:', this.yandexSdk.environment );

        this.setupLocale()    
        await this.initPlayer();        

        this.yandexSdk.getPayments({signed:true}).then( ( payments : any ) => {
            console.log( 'Yandex payments are available:', payments )
            this.payments = payments

            //also passively try to consume any hanging purchases (in case there was previosly network failure, power outage, etc):
            this.autoConsumePurchases()
        }).catch( ( err : any ) => {
            // Покупки недоступны. Включите монетизацию в консоли разработчика.
            // Убедитесь, что на вкладке Покупки консоли разработчика присутствует таблица
            // хотя бы с одним внутриигровым товаром и надписью «Покупки разрешены».
            console.log( 'Yandex payments are NOT available:', err )
        })
    }

    async initPlayer() {
        try {
            const player = await this.yandexSdk.getPlayer({
                //Если вам достаточно знать идентификатор, а имя и аватар пользователя не нужны, используйте опциональный параметр scopes: false:
                scopes: false,

                //Вы можете применить опциональный параметр signed: true и метод fetch, чтобы авторизовать пользователя и сохранять данные состояния игры на своем сервере. Это позволит вам использовать подпись для проверки подлинности игрока и избежать возможных накруток.:
                //seems like requires player to be logged in into Yandex:
                //signed: true,
            })
            this.player = player
            console.log( 'Yandex player:', player )
        }
        catch(err){
            // Если игрок не авторизован, выбрасывает исключение USER_NOT_AUTHORIZED.) {
            console.warn(err);
        }
        if(!this.player)
            return;
                
        this.doAuthroizationAndRetry( async ()=> this.doLogin() )        
    }

    setupLocale() {
        // refer https://yandex.ru/dev/games/doc/dg/sdk/sdk-environment.html
        const localeShort = this.yandexSdk.environment.i18n.lang

        const i18n = (<any>this.context.app.i18n)
        if(!i18n) {
            logger.warn("Not found i18n module");            
            return;
        }
        i18n.setLocale(localeShort);                
        i18n.setLocaleCookie(localeShort);                                 
    }

    async showFullscreenAd() : Promise<boolean | undefined > {
        if ( ! this.yandexSdk )
            return undefined

        return new Promise( ( resolve, reject )=>{
            this.yandexSdk.adv.showFullscreenAdv( {
                callbacks : {
                    onClose : ( wasShown : boolean ) => {
                        logger.log( 'Yandex: Fullscreen ad close. wasShown:', wasShown )
                        resolve( wasShown )
                    },
                    onError : ( error : any ) => {
                        logger.log( 'Yandex: Fullscreen ad error:', error )
                        resolve( false )
                    },
                },
            })
        })
    }
    async showRewardedVideo() : Promise<boolean | undefined > {
        if ( ! this.yandexSdk )
            return undefined

        return new Promise( ( resolve, reject )=>{
            this.yandexSdk.adv.showRewardedVideo( {
                callbacks : {
                    onOpen : () => {
                        logger.log( 'Yandex: Rewarding ad open' )
                    },
                    onClose : () => {
                        logger.log( 'Yandex: Rewarding ad close' )
                        resolve( false )
                    },
                    onError : ( error : any ) => {
                        logger.log( 'Yandex: Rewarding ad error: ', error )
                        resolve( false )
                    },
                    onRewarded : () => {
                        logger.log( 'Yandex: Rewarding ad reward' )
                        resolve( true )
                    },
                },
            })
        })
    }

    async autoConsumePurchases( signature : string | undefined = undefined )
    {
        //purchases was never implemented at dev/stage targets, so to mute console error messages:
        if (
            process.env.SERVER_API_URL_BASE
            &&
            (
                process.env.SERVER_API_URL_BASE.indexOf( "dev." ) >= 0
                ||
                process.env.SERVER_API_URL_BASE.indexOf( "stage." ) >= 0
            )
        ) {
            console.log( "Yandex: skipping purchases processing for current build target." )
            return
        }

        try {
            if ( ! signature )
                signature = ( await this.payments.getPurchases() ).signature
        }
        catch(e) {
            //probably connector error: re-request some time later:
            logger.error( 'Yandex purchases retrieval failed:', e, 'Retrying in 5 secs ...' )
            setTimeout( () => this.autoConsumePurchases(), 5000 )
            return
        }

        logger.log( 'Yandex purchases yet to be consumed:', signature )
        if ( ! signature )
            return
        
        let result
        try {
            result = await this.connectorUserYandex?.doConsumePurchase( signature )
        }
        catch(e) {
            logger.error( 'Yandex purchases consuming failed:', e, 'Retrying in 5 secs ...' )
            setTimeout( () => this.autoConsumePurchases(), 5000 )
            return
        }

        logger.log( 'Yandex purchases should be successfully consumed:', result )

        if ( result.message == 'handle_purchases' ) {
            for ( const p of result.purchases ) {
                if ( p.isConsumed != false )
                    continue
                
                let p_result
                try {
                    p_result = await this.payments.consumePurchase( p.purchaseToken )
                    logger.log( 'Yandex purchase', p, 'successfully consumed:', p_result )
                }
                catch(e) {
                    logger.log( 'Yandex purchase consuming failed:', e )
                    continue
                }
            }
        }
    }
           
}


