import { IUserInfo } from "../connectors/SocketConnector";
import UserConnector from "../connectors/UserConnector";
import TransportInterface from "../transport/TransportInterface";
import BgServerYandex from "./BgServerYandex";
import { engineVersion } from "mobile-device-detect";



export default class UserConnectorYandex extends UserConnector {
    server:BgServerYandex

    constructor(socket:SocketIOClient.Socket, transport:TransportInterface, server:BgServerYandex)  {                
        super(socket,transport)        
        this.server = server;
        this.rpcUrl = '/yandex-rpc';
    }
    

    async doLoginYandex(signature:string,anonimId:string) { 
        return this.__call("login-yandex", {signature,anonim_id:anonimId});
    };

    async doLoginYandexAnonim(anonimId:string) {
        return this.__call("login-yandex", {signature:null, anonim_id:anonimId});
    };    

    async doConsumePurchase( signature : string ) {
        return this.__call( "consume-purchase", { signature } )
    }

}