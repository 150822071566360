import { ISearchPagination, ISearchResult } from "../connectors/SocketConnector";
import TradeConnector from "../connectors/TradeConnector";
import ModelCollectionTrade from "../model/ModelCollectionTrade";
import TradeOffer, { ITradeOffer, ITradeOfferWithCurrency, TradeOfferUuid } from "../model/Types/TradeOffer";
import BgServerYandex from "./BgServerYandex";
import { ETradeOfferTypeId } from '~/modules/model/Types/TradeOffer'
import logger from "../utility/logger";



export default class TradeConnectorYandex extends TradeConnector {
    server:BgServerYandex

    tradeOfferList: ModelCollectionTrade

    constructor(socket:SocketIOClient.Socket, server:BgServerYandex) {
        super(socket);
        this.server = server;

        this.tradeOfferList = new ModelCollectionTrade()
    }
    

    async getYandexCatalog() {
        let result:any[] = [];

        await this.wrapLoading( async ()=>{
            try {
                result = await this.server.payments.getCatalog()
            } catch(e) {
                logger.error("Yandex getCatalogAsync error", e)
                // maybe set in store
                // ProninE : in case in developmnet we should set it as hardcoded for example 
                // but it not matter bcz we need dummy yandex sdk to run it at locahost
                result = this.getYandexCatalogDev();
            }
        });
        return result;
    }


    getYandexCatalogDev():any[] {
        if (process.env.NODE_ENV === "production") 
            return [];
        
        return [
            {id:"ya_20000_credits"  , price:2*70  , priceCurrencyCode: "YAN"},
            {id:"ya_60000_credits"  , price:5*70  , priceCurrencyCode: "YAN"},
            {id:"ya_140000_credits" , price:10*70 , priceCurrencyCode: "YAN"},
            {id:"ya_320000_credits" , price:20*70 , priceCurrencyCode: "YAN"},
            {id:"ya_1000000_credits", price:50*70 , priceCurrencyCode: "YAN"},
        ]
    }
    

    async doGetListSearch(search:ITradeOffer, pagination:ISearchPagination):Promise< ISearchResult < ITradeOfferWithCurrency >> {                        
        let [ result, yandex_result ] = await Promise.all( [ 
            super.doGetListSearch(search, pagination), 
            this.getYandexCatalog()        
        ]);

        logger.log( 'doGetListSearch should look like this:', result.models )
        logger.log( '    ... and Yandex products:', yandex_result )

        let models = this.mergeTradeOffers(result.models, yandex_result );
                                    
        //I couldn't understand this code and it causes problems:
        /*// if search is empty
        if(Object.keys(search).length !== 0) {        */
            this.tradeOfferList.clear();
            this.tradeOfferList.itemUpdateArray(models);            
        //}                

        return {...result, models}        
    };
    

    private mergeTradeOffers(tradeOffers: ITradeOfferWithCurrency[], yandexProducts : any[]) {
        //merge Facebook items into our server's data:        
        let offers = tradeOffers.filter(tradeOffer => {
            if ( tradeOffer.type_id != ETradeOfferTypeId.PURCHASE_YANDEX ) 
                return true;
            
            let product = yandexProducts.find( ( product : any ) => product.id == tradeOffer.product_id );
            if(!product) {
                logger.warn("Yandex' product equivalent was NOT resolved for product: "+ JSON.stringify( tradeOffer ));
                return false;
            }            
            tradeOffer.force_price_from = {
                price : product.price,
                currency_code : product.priceCurrencyCode,
            }  
            return true;          
        });

        return offers;
    }

    async doExecute(uuid:TradeOfferUuid) {
        let tradeOffer = this.tradeOfferList.get(uuid);

        if( !tradeOffer || !tradeOffer.isYandex() || !tradeOffer.product_id ) 
            return super.doExecute(uuid);
        
        await this.wrapLoading( () => this.doExecuteYandex(tradeOffer) )
    };

    async doExecuteYandex(tradeOffer:TradeOffer | undefined) {
        if(!tradeOffer || !tradeOffer.product_id)
            return;

        logger.log( 'Initiating Yandex purchase ...' )
        try {
            const purchase = await this.server.payments.purchase( { id : tradeOffer.product_id } )
            logger.log( 'Yandex Purchase:', purchase )
            await this.server.autoConsumePurchases( purchase.signature )
        }
        catch ( e ) {
            //ok: player just cancelled deal:
            //@ts-ignore            
            if ( e.code == 'USER_INPUT' )
                return "no I didn't purchased"
            throw e
        }       
    }
}

