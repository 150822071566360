
import moment from 'moment'

export function starts_in( t : number, d : string ) {
    const SECOND = 1000
    const MINUTE = SECOND * 60
    const HOUR = MINUTE * 60
    const DAY = HOUR * 24

    const starts = new Date( t * 1000 )
    const current = new Date
    let dif = starts.getTime() - current.getTime()

    if (dif <= 0 ) {
        return '00:00:00'
    }

    const days = Math.floor( dif / DAY );
    dif -= days * DAY

    const hours = Math.floor( dif / HOUR );
    dif -= hours * HOUR

    const minutes = Math.floor( dif / MINUTE )
    dif -= minutes * MINUTE

    const seconds = Math.floor( dif / SECOND );

    const s = (v:number) => v.toString().padStart( 2, '0' )

    let r = ''
    if ( days > 0 ) r += days + d + ' '
    let sec = ''
    if (days === 0 && hours < 1) sec = ':' + s(seconds)
    return r + s(hours) + ':' + s(minutes) + sec
}

export function print_timestamp( t : number ) {
    //@ts-ignore
    let locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
    return moment( t * 1000 ).format('L') + ' ' + moment( t * 1000 ).format('LTS')
}

export function print_timestamp_day( t : number ) {
    //@ts-ignore
    let locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
    return moment( t * 1000 ).format('LTS')
}
